<template>
    <nav class="">
    <div class="container">
        <div class="nav-column nav-main">
            <div class="nav-mainBlock">
                <svg width="36" height="50" viewBox="0 0 240 314" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.9999 88.9193L105.626 0.258789L239.231 134.946L202.533 171.748L104.951 73.5302L87.9555 90.4649L222.872 224.803L186.318 261.63L14.9999 88.9193Z" fill="var(--main-clr)"/>
                    <path d="M170.116 277.378L133.338 313.741L0.769531 180.792L37.5475 144.429L170.116 277.378Z" fill="var(--main-clr)"/>
                </svg>
                <div class="nav-title">Shuoki Rating</div>
            </div>
            <div class="toggle-button" @click="checkNav"><i class="fa-solid fa-bars"></i></div>
        </div>

        <div class="nav-column nav-toggle" id="nav-toggle">
            <a href="#" class="nav-login">Войти</a>
        </div>
    </div>
</nav>
</template>

<script>
export default {
    name: "MainHeader",
    data() {
        return {
            navbarOpen: false
        }
    },
    methods: {
        checkNav() {
            let nav = document.getElementById('nav-toggle')
            switch(this.navbarOpen) {
                case false:
                    nav.classList.add('active')
                    this.navbarOpen = true
                    break;
                case true:
                    nav.classList.remove('active')
                    this.navbarOpen = false
                    break
            }
        }
    }
}
</script>

<style>
nav {
    padding: 0;
    margin: 0;
    background: var(--second-clr);
}

nav .container {
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-mainBlock {
    display: flex;
    align-items: center;
    align-items: center;
    transition: .01s;
    cursor: pointer;
}

.nav-mainBlock:hover {
    color: var(--main-clr);
}

.nav-title {
    margin-left: 15px;
    font-size: 25px;
    font-family: "Ginto";
    padding-bottom: 3px;
    border-bottom: 3px solid var(--main-clr);
}
.nav-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.toggle-button {
    display: none;
    font-size: 25px;
    cursor: pointer;
}

.nav-login {
    display: block;
    padding: 15px 50px;
    background: rgb(225,225,225, 0.25);
    border-radius: 0.3rem;
    transition: .15s ease-in-out;
}

.nav-login:hover {
    /* transform: translateY(-2px); */
    background: rgb(225,225,225, 0.45);
    color: white;
}

.nav-login:active {
    background: rgb(225,225,225, 0.55);
}

@media (max-width: 625px) {
    nav .container {
        display: block;
        
    }

    .toggle-button {
        display: block;
    }

    #nav-toggle {
        max-height: 0;
        overflow: hidden;
        transition: max-height .3s ease-in-out;
    }

    #nav-toggle.active {
        max-height: 80px;
    }

    .nav-login {
        text-align: center;
        margin-top: 10px;
    }
}

@media (max-width: 355px) {
    .nav-title {
        font-size: 20px;
    }
}
</style>