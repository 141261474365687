<template>
    <div class="main">
        <div class="mainBlock">
            <div class="container">
                <div class="mainBlock-info">
                    <h1 class="mainBlock-title">Shuoki Rating System</h1>
                    <div class="mainBlock-description" style="font-weight:500; margin: 15px 0;">Сервис, позволяющий собрать информацию о <b class="">Discord боте</b> со всех популярных мониторингов и воспроизвести общую статистику.</div>
                    <div class="input-place">
                        <div class="input-parts">
                            <i class="fa-solid fa-magnifying-glass mainBlock-inputIcon"></i>
                            <input type="text" class="mainBlock-input" placeholder="ID Бота">
                            <button class="input-btn btn-blurple">Поиск</button>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 150" style="transform: rotate(180deg);">
            <path fill="#2f3136" class="wave" fill-opacity="1" d="M826.337463,25.5396311 C670.970254,58.655965 603.696181,68.7870267 447.802481,35.1443383 C293.342778,1.81111414 137.33377,1.81111414 0,1.81111414 L0,150 L1920,150 L1920,1.81111414 C1739.53523,-16.6853983 1679.86404,73.1607868 1389.7826,37.4859505 C1099.70117,1.81111414 981.704672,-7.57670281 826.337463,25.5396311 Z"></path>
        </svg>
    </div>
    <!-- <div class="f" style="margin-top:1000px">fff</div> -->
</template>
<script>
export default {
    name: "ShuokiMain"
}
</script>

<style lang="scss" scoped>

.wave {
    animation: style_waveDividerAnimation__zH_aO 20s linear infinite alternate;
}

@keyframes  style_waveDividerAnimation__zH_aO {
    0% {
    d: path("M826.337463,25.5396311 C670.970254,58.655965 603.696181,68.7870267 447.802481,35.1443383 C293.342778,1.81111414 137.33377,1.81111414 0,1.81111414 L0,150 L1920,150 L1920,1.81111414 C1739.53523,-16.6853983 1679.86404,73.1607868 1389.7826,37.4859505 C1099.70117,1.81111414 981.704672,-7.57670281 826.337463,25.5396311 Z");
    }
    50% {
        d: path("M655.558582,28.4898877 C500.191373,61.6062216 422.833785,83.5422763 266.940085,49.8995879 C112.480383,16.5663637 84.0992497,8.02840272 0,44.7243294 L0,150.727527 L1920,150.727527 L1920,49.8995879 C1580.91676,-34.8808285 1438.10804,73.6768193 1148.0266,38.0019829 C857.945166,2.32714659 810.925791,-4.62644617 655.558582,28.4898877 Z");
    }
    100% {
        d: path("M842.322034,38.0019829 C686.954825,71.1183168 623.386282,53.08497 467.492582,19.4422816 C313.032879,-13.8909426 84.0992497,8.02840272 0,44.7243294 L0,150.727527 L1920,150.727527 L1920,76.8685643 C1512.23604,-35.3595639 1568.70222,67.4289432 1278.62078,31.7541069 C988.539347,-3.92072949 997.689243,4.88564905 842.322034,38.0019829 Z");
    }
}
.mainBlock {
    background: var(--second-clr);
    padding: 30px 0;
}

.mainBlock-info {
    width: 60%;
    margin: 0 auto;
    background: rgb(0, 0, 0, 0.1);
    padding: 40px;
    border-radius: 1rem;
}

.mainBlock-title {
    font-family: "Ginto";
}
h1 {
    margin: 0;
    padding: 0;
}

.input-place {
    display: flex;
}

.input-parts {
    width: 100%;
}

.input-btn {
    text-align: center;
    padding: 10px 0;
    background: var(--main-clr);
    border-radius: 0.3rem;

    width: 21%;
    margin-left: 20px;
}








.mainBlock-input {
    display: inline;
    outline: 0;
    border: 0;
    width: 65%;
    background: var(--first-clr);
    padding: 7px;
    border: 2px solid transparent;
    color: var(--text-color);
    font-size: 18px;
    font-family: var(--main-font);
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    transition: all .2s ease-in-out;
}

.mainBlock-inputIcon {
    display: inline-block;
    width: 10%;
    font-size: 18px;
    text-align: center;
    // padding: 10px 0px 10px 0px;
    padding: 10px 10px 8px 20px;
    background: var(--first-clr);
    border: 3px solid transparent;
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.mainBlock-input:focus {
    border: 2px solid var(--main-clr);
    background: var(--second-clr);
}

@media (max-width: 1030px) {
    .mainBlock-info {
        width: 80%;
    }
}

@media (max-width: 785px) {
    .mainBlock-info {
        width: 100%;
    }
}

@media (max-width: 640px) {
    .input-btn {
        width: 100%;
        margin: 10px 0;
    }

    .mainBlock-input {
        width: 90%;
    }
}

@media (max-width: 597px) {
    .mainBlock-inputIcon  {
        width: 20%;
    }

    .mainBlock-input {
        width: 80%;
    }
}

@media (max-width: 392px) {
    .mainBlock-inputIcon  {
        width: 25%;
    }

    .mainBlock-input {
        width: 75%;
    }

    .mainBlock-title {
        font-size: 25px;
    }

    .mainBlock-description {
        font-size: 14.5px;
    }
}
</style>